import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Accordion, ButtonGroup, Button, Pagination, Image, Form } from 'react-bootstrap';
import { debounce } from 'lodash';
import Loader from '../../components/Loader/Loader';
import { REQUEST_STATUS } from '../../constants/loading';
import { DEVICES } from '../../constants/devices';
import {
  getSortByClassName,
  getUSFormat,
  getRoleLabel,
  getPreferredLangLabel,
} from '../../helpers/helpers';
import {
  fetchNotificationConfig,
  fetchPropertyList,
  fetchUsersList,
  updateNotificationConfig,
} from '../../services/dashBoardServices';
import AuthContext from '../../store/AuthContext';
import useFetchApiList from '../../hooks/useFetchApi';
import EditUserModal from '../../components/Users/EditUserModal';
import InviteUserModal from '../../components/Users/InviteUserModal';
import { deleteUser } from '../../services/dashBoardServices';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import NotificationModal from '../../components/Users/NotificationModal';
import NotificationIcon from '../../assets/images/icon-notification.png';
import { USER_ROLES } from '../../constants/roles';
import EditIcon from '../../assets/images/icon-edit.png';
import DeleteIcon from '../../assets/images/icon-delete.png';
import SearchIcon from '../../assets/images/icon-fc-search.png';

const initNotifiation = {
  show: false,
  user: null,
  data: null,
};

const pageSize = 20;

const userListSpecInitial = {
  currentPage: 1,
  offset: 0,
  pageSize,
  pageMax: 1,
  sortColumn: 'firstName',
  sortDirection: 'ASC',
  totalRecords: 0,
};

export default function UserList() {
  const authContext = useContext(AuthContext);
  const transformListSpec = useCallback(
    listSpec => {
      return {
        offset: (listSpec.currentPage - 1) * pageSize,
        pageSize,
        sortColumn: listSpec.sortColumn,
        sortDirection: listSpec.sortDirection,
        loggedInUserId: authContext.user.id,
        searchText: listSpec.searchText,
      };
    },
    [authContext.user.id]
  );

  const {
    list: userList,
    listSpec: userListSpec,
    listState: userListState,
    getListSortReq: getUserListSortReq,
    getListPageReq: getUserListPageReq,
    getListQueryReq: getUserListQueryReq,
    setListState: setUserListState,
  } = useFetchApiList({
    fetchApi: fetchUsersList,
    transformListSpec,
    listSpecInitial: userListSpecInitial,
  });

  const [toast, setToast] = useState({
    type: 'danger',
    show: false,
    message: '',
  });

  const [isEditForm, setIsEditForm] = useState(false);

  const notificationReducer = (state, action) => {
    switch (action.type) {
      case 'HIDE_MODAL':
        return { ...state, show: false, user: null, data: null };
      case 'SET_USER':
        return { ...state, show: true, user: action.payload.user, data: action.payload.data };
      default:
        break;
    }
  };

  const [notificationState, setNotificationState] = useState(REQUEST_STATUS.IDLE);
  const [notificationData, notificationDispatch] = useReducer(notificationReducer, initNotifiation);

  const [showUserModal, setshowUserModal] = useState(false);
  const [showUserInviteModal, setshowUserInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formUpdateState, setFormUpdateState] = useState(REQUEST_STATUS.IDLE);
  const [, deviceName] = useWindowDimensions();
  const [propertyList, setPropertyList] = useState([]);
  const [propertyListState, setPropertyListState] = useState(REQUEST_STATUS.IDLE);

  const setNotificationUser = async user => {
    setNotificationState(REQUEST_STATUS.LOADING);
    const res = await fetchNotificationConfig(user.id);
    if (res?.error === false) {
      setNotificationState(REQUEST_STATUS.SUCCESS);
      const data = res.data;

      notificationDispatch({
        type: 'SET_USER',
        payload: {
          user: { id: user.id, name: user.firstName + ' ' + user.lastName },
          data,
        },
      });
    } else {
      setNotificationState(REQUEST_STATUS.FAILED);
      const message = res.message ? res.message : 'Something went wrong. Try again later!';
      setToast({ show: true, message, type: 'danger' });
    }
  };

  const hideNotificationModal = () => {
    notificationDispatch({ type: 'HIDE_MODAL' });
  };

  const handleNotficationSubmit = async notificationConfig => {
    setNotificationState(REQUEST_STATUS.LOADING);
    const res = await updateNotificationConfig(notificationConfig);
    let message = 'Something went wrong. Try again later!';
    if (res.error === false) {
      setToast({
        show: true,
        message: 'Notifications settings updated successfully!',
        type: 'success',
      });
      setNotificationState(REQUEST_STATUS.SUCCESS);
    } else {
      setToast({ show: true, message, type: 'danger' });
      setNotificationState(REQUEST_STATUS.FAILED);
    }
    hideNotificationModal();
  };

  const getPropertyList = useCallback(async () => {
    const propList = await fetchPropertyList(authContext.user.id);
    const propListOptions = propList.propertyList.map(item => ({
      label: item.propertyName,
      value: item.propertyId,
    }));
    setPropertyList(propListOptions);
    setPropertyListState(REQUEST_STATUS.SUCCESS);
  }, [authContext.user]);

  useEffect(() => {
    if (propertyListState === REQUEST_STATUS.IDLE) {
      setPropertyListState(REQUEST_STATUS.PENDING);
    }
    if (propertyListState === REQUEST_STATUS.PENDING) {
      setPropertyListState(REQUEST_STATUS.LOADING);
      getPropertyList();
    }
  }, [getPropertyList, propertyListState]);

  const handleShowUserModal = user => {
    setIsEditForm(!!user);
    setSelectedUser(user);
    setshowUserModal(true);
  };

  const handleShowUserInviteModal = user => {
    setSelectedUser(user);
    setshowUserInviteModal(true);
  };

  const handleShowDeleteModal = user => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleNotificationModal = user => {
    setNotificationUser(user);
  };

  const hideUserModal = useCallback(
    reload => {
      setshowUserModal(false);
      if (reload) {
        setUserListState(REQUEST_STATUS.PENDING);
      }
    },
    [setUserListState]
  );
  
  const hideUserInviteModal = useCallback(
    reload => {
      setshowUserInviteModal(false);
      if (reload) {
        setUserListState(REQUEST_STATUS.PENDING);
      }
    },
    [setUserListState]
  );

  const hideDeleteModal = reload => {
    setShowDeleteModal(false);
    if (reload) {
      setUserListState(REQUEST_STATUS.PENDING);
    }
  };

  const handleDeleteUser = async id => {
    const resdeleteUser = await deleteUser(id);
    hideDeleteModal();
    if (resdeleteUser?.status && resdeleteUser?.status !== 200) {
      let message = resdeleteUser?.message
        ? resdeleteUser?.message
        : 'Ops! Something went wrong. Try again later';
      setToast({ show: true, message, type: 'danger' });
    }
    setUserListState(REQUEST_STATUS.PENDING);
  };

  const getSortByClass = useCallback(
    sortColumn => {
      return getSortByClassName(userListSpec, sortColumn);
    },
    [userListSpec]
  );

  const closeToast = () => {
    setToast(prevState => ({
      ...prevState,
      show: false,
    }));
  };

  const handleQuery = debounce(query => {
    getUserListQueryReq(query);
  }, 500);

  return (
    <>
      <Loader
        isLoading={
          userListState === REQUEST_STATUS.LOADING ||
          propertyListState === REQUEST_STATUS.LOADING ||
          formUpdateState === REQUEST_STATUS.LOADING ||
          notificationState === REQUEST_STATUS.LOADING
        }
      />
      <div className="header mb-3">
        <div className="container">
          <div className="header-body">
            <div className="list-header">
              <button
                className="btn btn-primary header-btn"
                onClick={() => {
                  handleShowUserModal();
                }}
                style={{ width: "100px" }}
              >
                Add User
              </button>
              <button
                className="btn btn-primary header-btn"
                onClick={() => {
                  handleShowUserInviteModal();
                }}
                style={{ width: "100px" }}
              >
                Invite User
              </button>
              <div className="icon-input">
                <span className="icon">
                  <Image src={SearchIcon} />
                </span>
                <Form.Control
                  readOnly={
                    userListState === REQUEST_STATUS.PENDING ||
                    userListState === REQUEST_STATUS.LOADING
                  }
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-users"
                  onChange={event => handleQuery(event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
          <>
            {userList?.map((user, index) => (
              <Accordion key={user.id} className="custom-accordion userob-accordion">
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div>
                      <h4 className="name">
                        {user.firstName} {user.lastName}
                      </h4>

                      <p className="role">{getRoleLabel(user?.userRole)}</p>
                      <p className="normal">{getUSFormat(user?.contactNumber)}</p>
                    </div>
                    <i className="toggle-arrow icon-chevron-right"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-4">
                      <h5 className="sub-text">LANGUAGE</h5>
                      <p className="normal">{getPreferredLangLabel(user.preferredLanguage)}</p>
                    </div>
                    {user.userRole !== USER_ROLES.SYSTEM_ADMIN && (
                      <div className="m-0 p-0">
                        <h5 className="sub-text">ASSOCIATED PROPERTIES</h5>
                        {user?.userPropertyDetailList?.map((property, index) => (
                          <ButtonGroup key={index} className="btn btn-light me-1 mb-1 p-1">
                            <Button
                              className="btn btn-light me-1 p-0"
                              onClick={() => handleShowUserModal(user)}
                            >
                              {property.propertyName}
                            </Button>
                          </ButtonGroup>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                  <div className="mb-3">
                    <div className="d-flex justify-content-around align content-center pb-2">
                      <button
                        className="btn btn-primary edit-delete-btn"
                        onClick={() => handleShowUserModal(user)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-outline-primary edit-delete-btn"
                        onClick={() => handleShowDeleteModal(user)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Accordion.Item>
              </Accordion>
            ))}
          </>
        ) : (
          <div className="card">
            <div className="table-responsive mb-0">
              <table className="table table-sm table-nowrap card-table head-light">
                <thead>
                  <tr>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('firstName')}`}
                        onClick={() => getUserListSortReq('firstName')}
                      >
                        User
                      </a>
                    </th>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('email')}`}
                        onClick={() => getUserListSortReq('email')}
                      >
                        Email address
                      </a>
                    </th>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('preferredLanguage')}`}
                        onClick={() => getUserListSortReq('preferredLanguage')}
                      >
                        Language
                      </a>
                    </th>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('userRole')}`}
                        onClick={() => getUserListSortReq('userRole')}
                      >
                        Role
                      </a>
                    </th>
                    <th>Associated Property</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {user?.firstName} {user?.lastName}
                          {user?.userRole !== USER_ROLES.SYSTEM_ADMIN && (
                            <button
                              className="btn btn-icon ms-3"
                              onClick={() => {
                                handleNotificationModal(user);
                              }}
                            >
                              <Image src={NotificationIcon} />
                            </button>
                          )}
                        </td>
                        {/* <td>{getUSFormat(user?.contactNumber)}</td> */}
                        <td>{user?.email}</td>
                        <td>{getPreferredLangLabel(user.preferredLanguage)}</td>
                        <td>{getRoleLabel(user.userRole)}</td>
                        <td className="position-relative">
                          <div className="d-flex after-comma">
                            <div className="text-truncate max-width-200">
                              {user?.userPropertyDetailList?.map((property, index) => {
                                return <span key={index}> {property.propertyName}</span>;
                              })}
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-icon"
                            onClick={() => {
                              handleShowUserModal(user);
                            }}
                          >
                            <Image src={EditIcon} />
                          </button>
                          <button
                            className="btn btn-icon ms-3"
                            onClick={() => {
                              handleShowDeleteModal(user);
                            }}
                          >
                            <Image src={DeleteIcon} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  {!userList?.length && (
                    <tr>
                      <td colSpan="8">
                        <p className="mb-0 py-4 text-center">Nothing Here</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end">
          {userListSpec.pageMax > 1 && (
            <div className="pagination-wrap">
              <div className="page-number">{`Page ${userListSpec.currentPage} of ${userListSpec.pageMax}`}</div>
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    onClick={() => getUserListPageReq(1)}
                    disabled={userListSpec.currentPage <= 1}
                  />
                  <Pagination.Prev
                    onClick={() => getUserListPageReq(userListSpec.currentPage - 1)}
                    disabled={userListSpec.currentPage <= 1}
                  />
                  <Pagination.Next
                    onClick={() => getUserListPageReq(userListSpec.currentPage + 1)}
                    disabled={userListSpec.currentPage >= userListSpec.pageMax}
                  />
                  <Pagination.Last
                    onClick={() => getUserListPageReq(userListSpec.pageMax)}
                    disabled={userListSpec.currentPage >= userListSpec.pageMax}
                  />
                </Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
      <NotificationModal
        show={notificationData.show}
        user={notificationData.user}
        data={notificationData.data}
        onHide={hideNotificationModal}
        onSubmit={handleNotficationSubmit}
      />
      <EditUserModal
        isEditForm={isEditForm}
        show={showUserModal}
        onHide={hideUserModal}
        userData={selectedUser}
        optionPropertyList={propertyList}
        showToast={setToast}
        setLoadingState={setFormUpdateState}
      />
      <InviteUserModal
        show={showUserInviteModal}
        onHide={hideUserInviteModal}
        optionPropertyList={propertyList}
        showToast={setToast}
        setLoadingState={setFormUpdateState}
      />
      <DeleteModal
        showModal={showDeleteModal}
        closeModal={hideDeleteModal}
        titleName={'User'}
        entityId={selectedUser?.id}
        entityName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
        onDelete={handleDeleteUser}
      />
      <ToastMessage
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onClose={closeToast}
      />
    </>
  );
}
